/* Daily Rates component */
.daily-rates-container {
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
  padding: 40px;
  border: 1px solid #C5C1C1;
}

.daily-rates-title {
  color: #476DB9;
  margin: 0px;
  font-size: 20px !important;
  font-weight: 400;
}


/* Daily Rates Table component */
.daily-rates-table-scrollarea {
  border: 1px solid #71c0ee;
}

.daily-rates-table-wrapper {
  background-color: #32439a;
  font-weight: bold;
  max-height: 47.1vh;
}

.daily-rates-thead-tr {
  background-color: #f0eeee;
  border: 1px solid #d1d5d8;
}

.daily-rates-th {
  min-width: 150px;
}

.daily-rates-th-text {
  color: #784595;
  font-size: 18px !important;
  font-weight: 400;
}

.daily-rates-tbody {
  background-color: #ffffff;
}

.daily-rates-tbody-tr {
  border: 1px solid #cac9c9;
  height: 3vw;
  background-color: #ffffff;
}

.daily-rates-td-text {
  color: #476db9;
  font-size: 16px !important;
  font-weight: 500;
}
