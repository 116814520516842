/* General */

* {
  box-sizing: border-box;
}

.mantine-AppShell-main {
  padding-top: calc(var(--mantine-header-height, 0px));
  padding-bottom: calc(var(--mantine-footer-height, 0px));
  padding-left: calc(var(--mantine-navbar-width, 0px));
  padding-right: calc(var(--mantine-aside-width, 0px));
}

.customSellectRightSection {
  border: 1px solid #c5c1c1;
  border-radius: 4px;
  margin: 6px 15px;
  padding: 4px 2px 1px 2px;

}

.mantine-Stack-root {
  gap: 0px;
}

html {
  font-size: 10px !important;
  text-rendering: geometricPrecision !important;
  background-color: white;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.chevron-border {
  border: 1px solid #001565 !important;
  width: 22px !important;
  padding: 2px !important;
}

/* .navbar-closed.chevron-border {
  left: 48px !important;
  -webkit-transform: rotate(180deg) !important;
  -moz-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  -o-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

.navbar-open .link-text {
  margin-left: 10px !important;
} */

.primary-color {
  color: #001565 !important;
}

.form-subtitle {
  font-size: 14px !important;
  color: black !important;
  font-weight: normal;
  font-style: normal;
}

.form-subtitle-large {
  font-size: 16px !important;
}

/* .navbar-open {
  min-width: 242px !important;
  margin-top: 70px !important;
  padding: 0 !important;
}

.navbar-closed {
  margin-top: 0 !important;
  background-color: #04aa6d;
} */

.modal-body-template .mantine-Modal-content {
  font-size: 14px;
  height: 95vh;
  max-height: calc(100vh - 150px);
  font-style: normal;
  font-weight: 500;
}

.modal-body-template-short {
  font-size: 14px;
  max-height: calc(100vh - 150px);
  font-style: normal;
  font-weight: 500;
}

.fit-content-modal .mantine-Modal-content {
  height: fit-content !important;
}

.mantine-Modal-content {
  font-size: 14px;
  max-height: calc(100vh - 150px);
  font-style: normal;
  font-weight: 500;
}

.mantine-Modal-content {
  border-radius: 8px;
}

.mantine-Input-input {
  font-size: 14px;
  height: 42px;
}

.modal-body-template .mantine-Grid-col {
  padding-top: 10px;
  padding-left: 25px;
}

.modal-body-template .right-col .mantine-Grid-col {
  width: 50%;
  padding-top: 5px !important;
  padding-left: 0;
}

.modal-body-template .left-col {
  border-right: 1px solid #ddd;
}

.modal-body-template h2 {
  font-size: 2.8rem !important;
}

.mantine-Modal-header {
  background: transparent;
}

.modal-body-template .stepper {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 20px;
  height: 43px;
  margin-bottom: 10px;
  margin-right: 25px;
  font-size: 10px !important;
}

.modal-body-template .stepper.active {
  color: #1a56db;
  border-color: #a4cafe;
  background-color: #a4cafe;
}

.modal-body-template .stepper.secondary-active {
  background: linear-gradient(0deg, #84e1bc, #84e1bc),
    linear-gradient(0deg, #f3faf7, #f3faf7);
  color: #046c4e !important;

  border: 1px solid #84e1bc;
}

.modal-body-template .stepper.active:hover {
  cursor: pointer;
}

.modal-body-template .stepper.secondary-active:hover {
  cursor: pointer;
}

.modal-body-template .stepper.inactive {
  color: #d1d5db;
  background-color: #f9fafb;
}

.modal-body-template .stepper-children {
  border: 1px solid #ddd;
  font-size: 14px;
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 15px;
  padding-left: 30px;
  width: 280px;
  height: 43px;
  margin-left: -16px;
  color: #000 !important;
}

.modal-body-template .stepper-children.active {
  color: #1a56db !important;
  border-color: #a4cafe;
  background-color: #a4cafe;
}

.modal-body-template .stepper-children.active:hover {
  cursor: pointer;
}

.modal-body-template .stepper-children.inactive {
  color: #d1d5db !important;
  background-color: #f9fafb;
}

/* .navbar-title-text {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  cursor: pointer !important;
  color: #001565 !important;
  margin-top: 16px !important;
}

.navbar-open .title-div {
  margin-top: 5px !important;
  margin-left: 25px !important;
}

.navbar-open ul.first-section {
  padding-left: 20px !important;
  margin-bottom: 35px !important;
}

.navbar-closed ul.first-section {
  padding: 0 !important;
  margin-bottom: 35px !important;
}

.navbar-open ul.second-section {
  padding-left: 20px !important;
}

.navbar-closed ul.second-section {
  padding: 0 !important;
}

.navbar-closed .title-div {
  margin-top: 10px !important;
}

.navbar-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;

  color: #001565 !important;
} */

html,
body {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

button.close-modal-button {
  height: 2rem !important;
}

button span {
  font-size: 12px !important;
}

#specialInstructions {
  font-size: 14px !important;
}

div.mantine-MultiSelect-values {
  height: 45px !important;
  line-height: 45px; /* Same as the height to center the content vertically */
}

.mantine-Select-dropdown {
  font-size: 14px !important;
}

.mantine-MultiSelect-value {
  height: 20px !important;
  font-size: 12px !important;
}

.mantine-Menu-item {
  font-size: 10px !important;
}

.mantine-Badge-inner {
  line-height: normal;
}

.mantine-Notification-root {
  box-sizing: border-box;
  z-index: 400;
  width: 632px;
  height: 68px;
  padding: 10px 20px 10px 20px !important;
  gap: 20px;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 4px 4px 0px #00000033;
}

.mantine-Notification-icon {
  width: 32.5px;
  height: 32.5px;
  gap: 0px;
}

.mantine-Notification-closeButton {
  width: 25px;
  height: 25px;
  gap: 0px;
}

.mantine-Notification-title {
  font-family: Plus Jakarta Sans;
  font-size: 18px !important;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}

.mantine-Notification-description {
  font-family: Plus Jakarta Sans;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 21px;
}

button.process-payment-btn {
  display: inline-block;
  width: auto;
  border-radius: 4px;
  font-weight: 600;
  position: relative;
  line-height: 1;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: #40c057;
  color: #fff;
}

/*table for lender association*/
.containerDiv {
  border-top: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
  width: 100%;
  display: table;
}
.rowDivHeader {
  background-color: #f8f8f8;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  font-weight: bold;
  display: table-row;
}
.rowDiv {
  border: 1px solid #e5e7eb;
  background-color: #fff;
  display: table-row;
}
.cellDivHeader {
  display: table-cell;
  width: 12%;
  padding: 8px;
  text-align: center;
  height: 40px;
}
.cellDiv {
  display: table-cell;
  width: 10%;
  padding-right: 5px;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
  padding-top: 5px;
}

.lastCell {
  border-right: none;
}

body {
  background-color: var(--primary);

  color: var(--black);
  font-family: var(--font-primary);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: rgb(0, 21, 101);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

input.form-input {
  height: 35px;
}

input::placeholder {
  font: 12px/3 Plus Jakarta Sans;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--white);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
}

.signupform {
  margin: 50px 150px 50px 150px;
  font-size: 14px;
  border: 1px solid #000;
  padding: 50px;
  border-radius: 0.8rem;
}

.signupform h2 {
  font-size: 20px;
  color: #000;
}

.signupform input[type='text'],
.signupform input[type='email'],
.signupform input[type='password'] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 10px 0;
  display: inline-block;
  border: 1px solid #ddd;
  background: #f1f1f1;
  height: 42px;
  font-size: 14px;
}

.signupform input[type='text']:focus,
.signupform input[type='email']:focus,
.signupform input[type='password']:focus {
  outline: none;
}

.signupform hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for all buttons */
.signupform .close-modal-button {
  background-color: #04aa6d;
  color: white;
  cursor: pointer;
}

.dzu-previewFileName {
  color: #fff !important;
}

.dzu-inputLabelWithFiles,
.dzu-submitButton {
  min-width: 8.4rem;
  border: 0.1rem solid var(--indigo);
  color: var(--white) !important;
  background: var(--indigo) !important;
  width: 25% !important;
  font-size: 1.6rem;
  margin-right: 1.6rem;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out;
}

.signupform .registerBtn {
  min-width: 8.4rem;
  border: 0.1rem solid var(--indigo);
  color: var(--white);
  background: var(--indigo);
  width: 20%;
  font-size: 12px;
  margin-right: 1.6rem;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: -0.2rem 0;
  border-radius: 0.8rem;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out;
}

.saveBtn {
  min-width: 8.4rem;
  border: 0.1rem solid var(--indigo);
  color: var(--white);
  background: var(--indigo);
  width: 20%;
  font-size: 1.6rem;
  margin-right: 1.6rem;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out !important;
}

.modal-body label {
  font-size: 14px;
}

.mantine-RadioGroup-description {
  font-size: 14px;
  color: #111928;
}

.modal-body label.switchLabel {
  font-size: 16px;
}
.accordion-table-head .mantine-Text-root {
  text-align: left;
  font-weight: bold;
  color: #495057;
}

.modal-body input.form-input {
  height: 42px !important;
  font-size: 14px !important;
}

.create-new-form .create-new {
  margin-top: -20px;
}

.create-new-form .mantine-Grid-root {
  height: 715px;
}

.create-new-form .stepper-col {
  border-right: 1px solid #e5e7eb;
}

.content .create-new .mantine-TextInput-input {
  height: 42px !important;
  font-size: 14px !important;
  border-radius: 1rem !important;
  background-color: #f9fafb;
}

.newInputWrapper {
  border: 1px solid #71c0ee;
}

.newInput {
  color: #476db9;
  font-size: 14px;
}

.customRightSection {
  margin-right: 7px; /* Add margin to the right of the icon */
  display: flex;
  align-items: center; /* Center the icon vertically */
}

.customSelectInput {
  color: #757373;
  font-size: 14px;
}

.content .create-new input[type='search'],
input.mantine-NumberInput-input,
.mantine-MultiSelect-input {
  border-radius: 1rem !important;
  background-color: #f9fafb;
}

.content .create-new .create-new-header {
  font-size: 20px !important;
  margin-top: -10px;
  margin-left: -5px;
  color: #000;
}

.content .mantine-Grid-col {
  padding-top: 0px;
  padding-left: 0px;
}

.modal-body .modal-body h1 {
  font-weight: bold !important;
  font-size: 14px !important;
  font-family: var(--font-primary) !important;
}

.signupform .loginBtn {
  min-width: 8.4rem !important;
  border: 0.1rem solid var(--indigo) !important;
  color: var(--white) !important;
  background: var(--indigo) !important;
  font-size: 1.6rem !important;
  width: 70px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 3.2rem !important;
  padding: 0.8rem 0 !important;
  border-radius: 0.8rem !important;
  text-align: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out !important;
  padding: 0.8rem 1.2rem !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
}

.signupform button:hover {
  opacity: 1 !important;
}

/* Float cancel and signup buttons and add an equal width */
.signupform .signupbtn {
  float: left !important;
  width: 50% !important;
}

/* Add padding to container elements */
.signupform .container {
  padding: 16px !important;
}

/* Clear floats */
.signupform .clearfix::after {
  content: '' !important;
  clear: both !important;
  display: table !important;
}

#input-file {
  min-width: 28.4rem !important;
  border: 0.1rem solid var(--indigo) !important;
  color: transparent !important;
  background: var(--indigo) !important;
  font-size: 1.6rem !important;
  width: 70px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 3.2rem !important;
  padding: 0.8rem 0 !important;
  border-radius: 0.8rem !important;
  text-align: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out !important;
  padding: 0.8rem 1.2rem !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
}

input[type='file'] {
  display: none !important;
}

.upload-file-select {
  min-width: 28.4rem !important;
  border: 0.1rem solid var(--indigo) !important;
  color: var(--white) !important;
  background: var(--indigo) !important;
  font-size: 1.6rem !important;
  width: 70px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 3.2rem !important;
  padding: 0.8rem 0 !important;
  border-radius: 0.8rem !important;
  text-align: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out !important;
  padding: 0.8rem 1.2rem !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
  margin: 0 auto !important;
}

button#file-upload-button {
  min-width: 8.4rem !important;
  border: 0.1rem solid var(--indigo) !important;
  color: var(--white) !important;
  background: var(--indigo) !important;
  font-size: 1.6rem !important;
  width: 70px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 3.2rem !important;
  padding: 0.8rem 0 !important;
  border-radius: 0.8rem !important;
  text-align: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  transition:
    background 0.3s ease-out,
    color 0.3s ease-out !important;
  padding: 0.8rem 1.2rem !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
}

.entity-create-form input {
  height: 42px !important;
}

.tabs-component {
  margin-top: 15px !important;
}

.tabs-component .content {
  margin-top: 0 !important;
}

.select-search-input {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #ddd !important;
  font-size: 14px !important;
  width: 100% !important;
  height: 42px !important;
}

.css-1dimb5e-singleValue {
  font-size: 14px !important;
}

.css-1nmdig5-menu {
  font-size: 14px !important;
}

.react-datepicker {
  font-size: 11px !important;
  background-color: var(--secondary) !important;
  color: #fff !important;
  width: 170% !important;
}

.react-datepicker__header {
  background-color: var(--primary) !important;
  width: 170% !important;
}

.react-datepicker__month {
  width: 170% !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 11px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000 !important;
}

.react-datepicker__day.react-datepicker__day--015.react-datepicker__day--selected.react-datepicker__day--today {
  color: #fff !important;
}

.react-datepicker-wrapper input {
  width: 100% !important;
}

/* Change styles for cancel button and signup button on extra small screens */
@media screen and (max-width: 420px) {
  .signupform .signupbtn {
    width: 100% !important;
  }
  .signupform {
    margin: 0 !important;
  }
}

.ag-root-wrapper {
  border: none !important;
}

.ag-theme-alpine {
  --ag-header-height: 45px !important;
  --ag-header-foreground-color: white !important;
  --ag-header-background-color: rgb(0, 0, 0, 0) !important;
}

.ag-theme-alpine .ag-header-group-cell {
  font-weight: 600 !important;
  font-size: 10px !important;
  line-height: 16px !important;
}
.ag-theme-alpine .ag-header-cell {
  font-size: 18px !important;
  color: #45526c !important;
}
.ag-header-row {
  border-color: #eeeef2 !important;
  border-style: solid !important;
  border-width: 1px 0px !important;
}
.ag-cell-label-container {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.ag-header {
  border: 0 !important;
}

.ag-theme-alpine .ag-icon-menu {
  background: transparent !important;
  color: transparent !important;
}

.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.ag-header-menu-always-show {
  opacity: 1 !important;
}
.ag-header-cell-menu-button:not(.ag-header-menu-always-show) {
  opacity: 1 !important;
}

.ag-cell {
  color: #262626 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.mantine-Paper-root {
  overflow-y: auto !important;
  width: 100%;
}

tr.noBorder {
  border: 0 !important;
}

tr.noBorder td {
  border-top: 0 !important;
}

.form-wrapper {
  margin-top: -10px;
  font-size: 14px;
}

.form-wrapper-title {
  color: #001565;
  font-size: 20px;
}

.form-wrapper-title-top-page {
  margin-top: -20px;
}

.form-no-stepper .mantine-Modal-body {
  padding-top: 20px;
}

.form-wrapper-body,
.mantine-Radio-label,
.mantine-Select-label,
.mantine-RadioGroup-description {
  font-style: normal;
}

.form-subtitle {
  color: #6b7280;
}

.form-button {
  height: 42px !important;
  font-size: 14px;
}

.iro-form-button {
  width: 109px;
  height: 33px !important;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 30px;
  border-radius: 5px;
}

.mantine-Modal-body .stepper-modal {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
}

.mantine-Modal-body .divider {
  border-left: 1px solid #e5e7eb;
  margin-top: -50px !important;
  height: 100vh;
  max-height: calc(95vh);
}

.mantine-Button-inner .mantine-Button-label .button-stepper {
  width: 100% !important;
}

.mantine-Dropzone-root {
  padding: 0;
  background-color: inherit;
  border: inherit;
  height: 22rem;
}

.mantine-Dropzone-root {
  padding: 0;
}

.wire-instruction-subtitle {
  line-height: 0.3;
  margin-top: 5px;
  text-decoration: underline;
  font-size: 1.2em;
}
.mantine-Dropzone-inner {
  pointer-events: all;
}

.accordions-header-part {
  width: 40%;
  height: 100%;
  align-items: flex-end;
}

.data-header-part {
  width: 60%;
  height: 100%;
  align-items: flex-start;
}

.topFormErrorText {
  color: red;
  width: 100%;
  text-align: center;
}

.warningText {
  color: orangered;
  text-align: center;
}

.rightErrorText {
  color: red;
  font-size: 12px;
  width: 100%;
  margin-left: 55%;
  font-weight: 500;
  text-decoration: none;
  line-height: 1.2;
  font-family: Plus Jakarta Sans;
  float: right;
}

.scrollBox {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  line-height: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}
.hoverScroll {
  position: absolute;
  white-space: nowrap;
  transform: translateX(1);
  transition: 1s;
}
.hoverScroll:hover {
  transform: translateX(calc(5% - 50%));
}

.checkbox-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.checkbox-row > div {
  flex: 1;
}

.autoExtendDetails {
  margin-bottom: 0;
  margin-top: 5px;
}

.basicFontImportant {
  font-size: 1.3em !important;
  font-family: 'Plus Jakarta Sans' !important;
}

.jobSummaryTitle {
  background-color: #32439a;
  color: white;
  padding: 10px;
  margin-top: 10px;
  height: 35px;
  display: flex;
}

.tableSummary {
  background-color: #001565;
  color: white;
  font-weight: 600;
}

.tableSummary:hover {
  background-color: #001565 !important;
}
.lenderTable tr:last-child td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 35px;
}

.lenderTable tr:last-child td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.notes-bold {
  font-weight: bold;
}

.mantine-Modal-close {
  width: 10px;
  position: fixed;
  top: 10px;
  right: 10px;
  margin-bottom: -20px;
}

.action-ion-icon {
  font-size: 14px;
}

.click-ion-icon {
  cursor: pointer;
}

.datePickerErrorMsg {
  margin-bottom: -20px;
  margin-top: -10px;
}

.pageLayoutContent {
  display: flex;
  width: 100%;
  padding: 30px 28px;
  flex-direction: column;
  gap: 30px;
}

.pageTitleUpdated {
  color: #001565;
  font-family: 'Plus Jakarta Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.pageTitleGap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.tabsGapsActivity {
  border-bottom: 4px solid #95a4fd;
  position: relative;
  margin-bottom: 20px; /*this is only 20 because we have an extra 10px margin on summary section so it display correctly */
}

.primaryInput {
  width: Fill (152.81px) px;
  height: Hug (33px) px;
  padding: 6px 30px 6px 30px;
  gap: 10px;
  border-radius: 5px 0px 0px 0px;
  background-color: #32439a;
}

.primaryInputText {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
}

.detailsHeader {
  margin-top: 30px;
  margin-bottom: 30px;
}

.detailsTitle {
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #001565;
  margin-right: 30px;
}

.pillStyles {
  width: 129px;
  height: 33px;
  padding: 6px 10px 6px 10px;
  gap: 10px;
  border-radius: 5px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
}

.primaryInputChevronDown {
  width: 9.19px;
  height: 5.25px;
  padding-left: 10px;
  font-weight: 600;
  position: relative;
  top: 2.5px;
}

.drawerTitle {
  font-family: Plus Jakarta Sans;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #001565;
}

.drawerSubTitle{
  font-family: Plus Jakarta Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #656FAB;
  margin-top: -20px;
}

.drawerCloseButton {
  width: 24.38px !important;
  height: 24.38px !important;
  gap: 0px;
  color: #6f6f6f;
  cursor: pointer;
}

.NavBarIconWhiteFilled > path {
  fill: white;
}

.NavBarIconWhiteStroke > path {
  stroke: white;
}
.modalCloseButton {
  width: 20.62px !important;
  height: 20.62px !important;
  gap: 0px;
  color: #6f6f6f;
  cursor: pointer;
}

.supportBtn{
  width: 175px;
  height: 33px;
  padding: 6px 30px 6px 30px;
  gap: 10px;
  border-radius: 5px;
  background-color: #32439A;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #FFFFFF
}