.filterMenu {
    width: 800px; /* Hug width */
    height: 281px; /* Hug height */
    border-radius: 12px; /* Radius */
    border: 2px solid #71C0EE; /* Border */
    padding: 40px; /* Padding */
    background-color: #F1ECEC; /* Background color */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Drop shadow */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filterMenuSelected {
    width: 720px; /* Hug width */
    height: 201px; /* Hug height */
    gap: 40px; /* Spacing between child elements */
    display: flex;
    justify-content: space-between; /* Aligns children to left and right */
    align-items: center; /* Aligns children vertically */
  }

.filterMenuLeft {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    width: 304px; /* Fixed width for the left div */
    height: auto; /* Fixed height for the left div */
    gap: 10px;
    padding: 10px; /* Padding inside the container */  
}

.filterMenuRight {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    width: 376px; /* Fixed width for the right div */
    height: auto; /* Fixed height for the right div */
    gap: 10px;
    padding: 10px; /* Padding inside the container */  
}

.textTradeStatus {
    width: 109px; /* Width */
    height: 22px; /* Height */
    /* font-family: 'Inter', sans-serif; Font */
    font-weight: 400; /* Weight */
    font-size: 18px; /* Font size */
    line-height: 21.78px; /* Line height */
    color: #3F036D; /* Text color */
}

.statusTrade {
    width: 164px; /* Fill width */
    height: 42px; /* Hug height */
    border-top: 1px solid #71C0EE; /* Top border */
    padding: 10px 20px; /* Top/Bottom: 10px, Left/Right: 20px */
    background-color: #FFFFFF; /* Background color */
    display: flex; /* For alignment */
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space between child elements */
    gap: 10px; /* Spacing between child elements */
}

.tradeStatus {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    color: #3F036D; /* Purple text color */
    width: 109px; /* Explicit width */
    height: 22px; /* Explicit height */
}

.tradeStatusValue {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    color: #476DB9; /* Blue text color */
    width: 100px; /* Explicit width */
    height: 22px; /* Explicit height */
}
  
  .customBadge {
    height: 46px; /* Hug height */
    background-color: #FFFFFF; /* Background color */
    border: 2px solid #71c0ee; /* Border color */
    border-radius: 10px; /* Rounded corners */
    padding: 10px 15px; /* Padding inside the badge */
    font-family: 'Plus Jakarta Sans', sans-serif; /* Match the font */
    font-weight: 400; /* Adjust font weight */
    font-size: 16px; /* Adjust font size */
    gap: 5px; /* Space between text and icon */
    
  }
  
  .customBadgeText {
    font-family: 'Plus Jakarta Sans'; /* Match the font */
    font-weight: 600; /* Make the label bold */
    font-size: 18px;
    color: #784595; /* Purple color */
    text-transform: none; /* Ensure no automatic text transformation */
  }

  .customBadgeTextValue {
    font-family: 'Plus Jakarta Sans'; /* Match the font */
    font-weight: 600; /* Make the label bold */
    font-size: 20px;
    line-height: 30px;
    color: #476DB9; /* Purple color */
    text-transform: none; /* Ensure no automatic text transformation */
  }

  .container {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.plusButton {
  width: 58px;
  height: 44px;
  border-radius: 10px;
  border: 2px solid #71C0EE;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plusButtonOpen {
  background-color: #32439A;
  color: #FFFFFF;
}

.plusButtonOpen:hover {
  background-color: #32439A;
  color: #FFFFFF;
}

.plusButtonClosed {
  background-color: #FFFFFF;
  color: #32439A;
}

.popoverDropdown {
  transform: translateX(30%);
}

.filterMenuForm {
  display: flex;
  flex-direction: column;
}

.filterMenu {
  display: flex;
}

.filterMenuLeft,
.filterMenuRight {
  display: flex;
  flex-direction: column;
}

.selectInput {
  width: 164px;
  height: 42px;
}

.datePickerInput {
  width: 164px;
  height: 42px;
}

.applyButton {
  width: 200px;
  background-color: #32439A;
  color: #FFFFFF;
  height: 40px;
  border-radius: 8px;
  margin-right: -20px;
  align-self: flex-end;
}

.textLabelInputContainerTradeView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
}

.textLabelTradeView {
  text-align: right;
  width: 50%;
  height: 22px;
  color: #3F036D;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
}

.textContentTradeView {
  width: 50%;
  text-align: left;
}

.placeholderTextTradeView {
  color: #476DB9;
}